import '../../../styles/pages/spring-summer-2021/touchdown-on-the-red-planet.scss';
import React from "react";
import Layout from '../../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../../components/spring-summer-2021-stories/more-stories"
import Link from "gatsby-plugin-transition-link/AniLink";

import socialBanner from "../../../images/spring-summer-2021/social-banners/touchdown-on-the-red-planet.jpg";

import jonLucChristensen from '../../../images/spring-summer-2021/touchdown-on-the-red-planet/JonLuc-Christensen.jpg';

export default class Index extends React.Component {

  render() {
    var pageTitle = 'Touchdown on the Red Planet';
    var pageDesc = 'Mechanical engineering alumna describes ‘seven minutes of terror’ before historic Mars 2020 landing.';
    var slug = 'touchdown-on-the-red-planet';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="mars-wrapper">
          <article className="wrap small story-content dark-content history-buff" style={{marginTop: '120px'}}>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <ul className="pager">
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/">1</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">2</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">3</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link active"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/history-buff-space-career/">4</Link>
              </li>
            </ul>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className="sidebar-hero">
              <img src={jonLucChristensen} alt="JonLuc Christensen"  />
            </figure>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h1>History Buff Lands a Space Career </h1>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p><span className="intro-text">At Cal State Fullerton, JonLuc Christensen</span> ’16 (B.A. history) learned how to think like a historian. Now as a records management specialist at NASA’s Jet Propulsion Laboratory in Pasadena, he is archiving the history of space missions at the JPL Archives and assisting in Freedom of Information Act requests for public records.</p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              <strong>The most exciting part of my job at JPL is … </strong><br />
              Working with some of the world’s most brilliant scientists and engineers, and preserving their accomplishments for future generations. Nothing is more exciting than watching a mission successfully launch, like Mars 2020, and knowing that you were a small part of it. 
            </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              <strong>My role in the Perseverance rover project is ....</strong><br />
              To handle, process and store some of the project’s records. The larger part of my role with Perseverance will begin when the mission ends and documentation for the JPL Archive begins.
            </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              <strong>I landed a career at JPL because ...</strong><br />
              My education at Cal State Fullerton in history became the foundation for my graduate studies and work. I am a proud member of the Phi Alpha Theta and Lambda Alpha honor societies at CSUF, and produced my first scholarly article in CSUF’s Welebaethan: A Journal of History. I completed a master’s degree in library and information sciences at San José State University, and obtained a summer internship at JPL that eventually turned into a full-time position.
            </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              <strong>Three CSUF educators who stand out are ...</strong><br />
              <ul>
                <li>Jochen Burgtorf, professor of history, who encouraged me to take risks.</li>
                <li>David Freeman, lecturer in history, who has been an incredible mentor.</li>
                <li>Ronald Rietveld, professor emeritus of history, who inspired me to follow my academic passions and to explore outside my comfort zone.</li>
              </ul>
            </p>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>
              <strong>To be successful with a history degree … </strong><br />
              Use your transferable skills — storytelling, writing and learning from past experiences — and do not be afraid to reach for jobs that are not directly related to the field. I strongly believe that historians make for incredible “bias sorters,” communicators, problem-solvers and conflict managers. <span className="end-mark" />
            </p>
            </ScrollAnimation>

          </article>

          <br /><br /><br />

          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" className="center">
            <Link
            className="button mars-button"
            cover
            bg="
            url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
            center / cover   /* position / size */
            no-repeat        /* repeat */
            scroll           /* attachment */
            padding-box      /* origin */
            content-box      /* clip */
            black            /* color */
            "
            direction="right"
            duration={2}
            to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">
              Previous
            </Link>
          </ScrollAnimation>

        </div>

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 